import * as React from "react";
import { SeoComponent } from "../components/atoms";
import {
  HeaderComponent,
  FooterComponent,
  SectionComponent,
} from "../components/organisms";

const PrivacyPage = () => {
  return (
    <>
      <HeaderComponent />
      <SectionComponent id="privacy-policy" title="プライバシーポリシー">
        <>
          <p>
            HANAYA
            Tech合同会社(以下、「当社」といいます。)は、当社が提供するサービスにおける個人情報の取り扱いについて、以下の通りプライバシーポリシー(以下、「本ポリシー」といいます。)を定めます。
          </p>
          <h3>第1条(個人情報)</h3>
          <p>
            「個人情報」とは、個人情報保護法でいう「個人情報」を指すものとし、生存する個人に関する情報であって，
            当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、
            及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報(個人識別情報)を指します。
          </p>
          <h3>第2条(個人情報の収集方法)</h3>
          <p>
            当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号、運転免許証番号などの個人情報をお尋ねすることがあります。
            また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を当社の提携先(情報提供元、広告主、広告配信先などを含みます。以下、「提携先」といいます。)などから収集することがあります。
          </p>
          <h3>第3条(個人情報を収集・利用する目的)</h3>
          <p>
            当社が個人情報を収集・利用する目的は、以下のとおりです。
            <ol>
              <li>当社サービスの提供・運営のため</li>
              <li>
                ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
              </li>
              <li>
                ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
              </li>
              <li>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
              <li>
                利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため
              </li>
              <li>
                ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため
              </li>
              <li>有料サービスにおいて、ユーザーに利用料金を請求するため</li>
              <li>上記の利用目的に付随する目的</li>
            </ol>
          </p>
          <h3>第4条(個人情報の第三者提供)</h3>
          <p>
            <ol>
              <li>
                当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく第三者に個人情報を提供することはありません。
                ただし、個人情報保護法その他の法令で認められる場合を除きます。
                <ol>
                  <li>
                    人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                  </li>
                  <li>
                    公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                  </li>
                  <li>
                    国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、
                    本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                  </li>
                  <li>
                    予め次の事項を告知あるいは公表をしている場合
                    <ol>
                      <li>利用目的に第三者への提供を含むこと</li>
                      <li>第三者に提供されるデータの項目</li>
                      <li>第三者への提供の手段または方法</li>
                      <li>
                        本人の求めに応じて個人情報の第三者への提供を停止すること
                      </li>
                      <li>本人の求めを受け付ける方法</li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
                <ol>
                  <li>
                    当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                  </li>
                  <li>
                    合併その他の事由による事業の承継に伴って個人情報が提供される場合
                  </li>
                  <li>
                    個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、
                    利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
                  </li>
                </ol>
              </li>
            </ol>
          </p>
          <h3>第5条(個人情報の開示)</h3>
          <p>
            <ol>
              <li>
                当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。
                ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合にはその旨を遅滞なく通知します。
                なお、個人情報の開示に際しては、1件あたり1,000円の手数料を申し受けます。
                <ol>
                  <li>
                    本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                  </li>
                  <li>
                    当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                  </li>
                  <li>その他法令に違反することとなる場合</li>
                </ol>
              </li>
              <li>
                前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
              </li>
            </ol>
          </p>
          <h3>第6条(個人情報の訂正および削除)</h3>
          <p>
            <ol>
              <li>
                ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除(以下、「訂正等」といいます。)を請求することができます。
              </li>
              <li>
                当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく当該個人情報の訂正等を行うものとします。
              </li>
              <li>
                当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なくこれをユーザーに通知します。
              </li>
            </ol>
          </p>
          <h3>第7条(個人情報の利用停止等)</h3>
          <p>
            <ol>
              <li>
                当社は、ユーザー本人から、個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、
                その利用の停止または消去(以下、「利用停止等」といいます。)を求められた場合には遅滞なく必要な調査を行います。
              </li>
              <li>
                前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく当該個人情報の利用停止等を行います。
              </li>
              <li>
                当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なくこれをユーザーに通知します。
              </li>
              <li>
                前2項にかかわらず、利用停止等に多額の費用を有する場合、その他利用停止等を行うことが困難な場合であって、
                ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
              </li>
            </ol>
          </p>
          <h3>第8条(プライバシーポリシーの変更)</h3>
          <p>
            <ol>
              <li>
                本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく変更することができるものとします。
              </li>
              <li>
                当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
              </li>
            </ol>
          </p>
          <h3>第9条(お問い合わせ窓口)</h3>
          <p>
            本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
          </p>
          <table>
            <tbody>
              <tr>
                <th>社名</th>
                <td>HANAYA Tech合同会社</td>
              </tr>
              <tr>
                <th>住所</th>
                <td>神奈川県川崎市高津区溝口1-9-7長谷川ビル5F</td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>privacy@hanaya-tech.com</td>
              </tr>
            </tbody>
          </table>
          <h3>第10条(SSLについて)</h3>
          <p>
            当社のWebサイトはSSL(Secure Socket
            Layer)に対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。
            ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
          </p>
          <h3>第11条(Cookieについて)</h3>
          <p>
            <ol>
              <li>
                当社のWebサイトでは、アクセス解析などを目的としてCookieを使用することがあります。
                アクセス解析には、Google Analyticsを使用し、Google
                Analyticsはトラフィックデータの収集のためにCookieを使用します。
                このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
              </li>
              <li>
                Google Analyticsがデータを収集、処理する仕組みについては、
                <a href="https://www.google.com/intl/ja/policies/privacy/partners/">
                  ユーザーがGoogleパートナーのサイトやアプリを使用する際のGoogleによるデータ使用
                </a>
                をご確認ください。
              </li>
              <li>
                Cookieを無効にすることで収集を拒否することが出来ます。
                Cookieを無効にする場合は、お使いのブラウザの設定をご確認ください。
              </li>
            </ol>
          </p>
          <h3>第12条(免責事項について)</h3>
          <p>
            <ol>
              <li>
                当社Webサイトに掲載されている情報の正確さには万全を期していますが、ユーザーが当社Webサイトの情報を用いて行う一切の行為に関して、当社は一切の責任を負わないものとします。
              </li>
              <li>
                当社は、ユーザーが当社Webサイトを利用したことにより生じたユーザーの損害及びユーザーが第三者に与えた損害に関して、一切の責任を負わないものとします。
              </li>
            </ol>
          </p>
          <h3>プライバシーポリシーの制定日及び改定日</h3>
          <table>
            <tbody>
              <tr>
                <th>制定</th>
                <td>2022年10月03日</td>
              </tr>
            </tbody>
          </table>
          <p>以上</p>
        </>
      </SectionComponent>
      <FooterComponent />
    </>
  );
};

export default PrivacyPage;
export const Head = () => (
  <SeoComponent title="プライバシーポリシー" pathName="/privacy" />
);
